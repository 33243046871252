dmx.Component('bs5-alert', {

    constructor: function(node, parent) {
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        dmx.BaseComponent.call(this, node, parent);
    },

    attributes: {
        show: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'primary' // primary, secondary, success, danger, warning, info, light, dark
        },

        closable: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            this[this.$node.classList.contains('show') ? 'hide' : 'show']();
        },

        show: function() {
            this.show();
        },

        hide: function() {
            this.hide();
        },

        setType: function(style) {
            this.setType(style);
        },

        setTextContent: function(text) {
            this.setTextContent(text)
        }
    },

    render: function(node) {
        this.$node = node;
        this.$parse();

        node.setAttribute('role', 'alert');
        node.classList.add('alert');

        this.$closeButton = document.createElement('button');
        this.$closeButton.setAttribute('type', 'button');
        this.$closeButton.setAttribute('aria-label', 'Close');
        this.$closeButton.className = 'btn-close';
        this.$closeButton.addEventListener('click', this.hide.bind(this));
        
        if (!this.props.show) {
            this.$node.style.setProperty('display', 'none');
        }

        this.update({});
    },

    update: function(props) {
        if (props.type != this.props.type) {
            this.setType(this.props.type);
        }

        if (props.show != this.props.show) {
            this[this.props.show ? 'show' : 'hide']();
        }

        if (props.closable != this.props.closable) {
            if (this.props.closable) {
                this.$node.appendChild(this.$closeButton);
                this.$node.classList.add('alert-dismissible');
            } else {
                this.$closeButton.remove();
                this.$node.classList.remove('alert-dismissible');
            }
        }
    },

    show: function() {
        this.$node.removeEventListener('transitionend', this.onTransitionEnd);
        this.$node.style.removeProperty('display');
        this.$node.offsetWidth;
        this.$node.classList.add('show');
    },

    hide: function() {
        this.$node.removeEventListener('transitionend', this.onTransitionEnd);
        if (this.$node.classList.contains('fade')) {
            this.$node.addEventListener('transitionend', this.onTransitionEnd, { once: true });
        } else {
            this.$node.style.setProperty('display', 'none');
        }
        this.$node.classList.remove('show');
    },

    onTransitionEnd: function() {
        this.$node.style.setProperty('display', 'none');
    },

    setType: function(type) {
        var types = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].map(function(type) { return 'alert-' + type });
        this.$node.classList.remove.apply(this.$node.classList, types);
        this.$node.classList.add('alert-' + type);
    },

    setTextContent: function(text) {
        this.$closeButton.remove();
        this.$node.textContent = text;
        if (this.props.closable) {
            this.$node.appendChild(this.$closeButton);
        }
    }

});
